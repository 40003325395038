import React, { useState } from "react";
import { getSlug } from "./utils/AdminHelper.js";
import { Button, Checkbox, Chip, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Image, Input } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import axios from "axios";
import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Main = () => {


  // const AllModel= useSelector(state=>state.dynamicAdminData.initialModelData)//json.initialModelData

  const [state, setState] = useState({
    modelName: "",
    modelKey: "",
    modelTag: "",
    isPrompt: false,
    samplePrompt: "",
    isNegativePrompt: false,
    negativePromptPresets: "",
    negativePromptName: "",
    isInitialImage: false,
    isNumberOfGeneration: false,
    isAspectRatio: false,
    aspectRatioName: "",
    aspectRatioWidth: "",
    aspectRatioHeight: "",
    isMusicIcon: false,
    isTrending: false,
    categoryName: null,
    category: null,
    modelIndex: 1,
    apiPath: "",
    method: "",
    // Added fields
    isStyle: false,
    isAdvanceSetting: false,
    refimage2: null,
    iscountry: false,
    isbgcolor: false,
    isrenderMode: false,
    refimage3: null,
    faceimage: null,
    isQuality: false,
    style2: "",
    isoutfit: false,
    isRemoveBg: false,
    islanguage: false,
    isgender: false,
    isvoice: false,
    isformate: false,
    isduration: false,
    addByMusic: false,
    isDuration2: false,
    isplatform: false,
    addAiMusic: false,
    addAivoiceover: false,
    addAicaption: false,
    isAdvanceSetting2: false,
  });

  const [tags, setTags] = useState([]);
  const [samplePrompts, setSamplePrompts] = useState([]);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [aspectRatio, setAspectRatios] = useState([]);
  const [negativeSamplePrompt, setNegativeSamplePrompt] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [urlKey, setUrlKey] = useState([]);

  // const [url] = useState(`https://videotool.appypie.com/playground/flux1`); // Replace with your actual URL

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlKey).then(() => {
      alert("URL copied to clipboard!");
    });
  };

  // useEffect(()=>{
  //   console.log("AllModel:", AllModel);
  // },[AllModel])

  const handleInput = (e) => {
    // error.status && setError({ status: false, message: null });
    const { name, type, checked, value } = e?.target && e?.target;
    if (name === "modelName") {
      const autoSlug = getSlug(value);
      setState({ ...state, modelName: value, modelKey: autoSlug });
    } else {
      setState({ ...state, [name]: type === "checkbox" ? checked : value });
    }
  };
  const handleDropdownselect = (e, dropdownname) => {
    setState({ ...state, categoryName: e?.anchorKey });
  };
  const handleDropdownIndex = (e, dropdownname) => {
    setState({ ...state, modelIndex: e?.anchorKey });
  };
  const handleClose = (tagToRemove, stateToRemove) => {
    if (stateToRemove === "samplePrompt") {
      setSamplePrompts(samplePrompts.filter((tag) => tag !== tagToRemove));
    } else if (stateToRemove === "aspectRatio") {
      setAspectRatios(aspectRatio.filter((tag) => tag !== tagToRemove));
    } else if (stateToRemove === "tag") {
      setTags(tags.filter((tag) => tag !== tagToRemove));
    } else if (stateToRemove === "negativeSamplePrompt") {
      setNegativeSamplePrompt(negativeSamplePrompt.filter((tag) => tag !== tagToRemove));
    } else if (stateToRemove === "uploadedImage") {
      setUploadedImage(uploadedImage.filter((tag) => tag !== tagToRemove));
    }
  };
  const handleAddTag = (stateKey) => {
    if (stateKey === "tags") {
      setTags([...tags, state.modelTag]);
      setState({ ...state, modelTag: "" });
    } else if (stateKey === "samplePrompts") {
      setSamplePrompts([...samplePrompts, state.samplePrompt]);
      setState({ ...state, samplePrompt: "" });
    } else if (stateKey === "aspectRatio") {
      setAspectRatios([
        ...aspectRatio,
        { name: state.aspectRatioName, width: state.aspectRatioWidth, height: state.aspectRatioHeight, key: state?.aspectRatioName?.toLowerCase() },
      ]);
      setState({ ...state, aspectRatioName: "", aspectRatioWidth: "", aspectRatioHeight: "" });
    } else if (stateKey === "negativeSamplePrompt") {
      setNegativeSamplePrompt([
        ...negativeSamplePrompt,
        { name: state.negativePromptName, value: state.negativePromptPresets, key: state?.negativePromptName?.toLowerCase() },
      ]);
      setState({ ...state, negativePromptPresets: "", negativePromptName: "" });
    }
  };

  const handleModelFile = async (e) => {
    const files = Array.from(e.target.files);

    if (uploadedImage.length + files.length > 5) {
      alert("You can upload a maximum of 5 images.");
      return;
    }

    const uploadedFiles = await Promise.all(
      files.map(async (file) => {
        const formdata = new FormData();
        formdata.append("image", file, file.name);
        formdata.append("userId", "00");

        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        try {
          const response = await fetch("https://us-central1-appydesigne-24e6c.cloudfunctions.net/checkImageNsfwsFilter", requestOptions);
          const result = await response.json(); // Parse as JSON

          // Check if nsfw_detected is false before proceeding
          if (result.nsfw_detected === false && result.imageUrl) {
            return result.imageUrl;
          } else {
            console.warn("NSFW content detected, skipping image.");
            return null; // Skip if NSFW content is detected
          }
        } catch (error) {
          console.error("Error during NSFW check:", error);
          return null; // Skip the file if there's an error
        }
      })
    );

    // Filter out any failed or NSFW uploads
    const successfulUploads = uploadedFiles.filter((url) => url !== null);
    setUploadedImage((prevFiles) => [...prevFiles, ...successfulUploads]);
  };

  const BASE_URL = "https://us-central1-appydesigne-24e6c.cloudfunctions.net/dynamicModelApi";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Function to create input data for API calls
  const createInputData = () => {
    const input = {
      key: state.modelKey,
      categoryName: state.categoryName,
      category: state.category,
      interface: "stable-diffusion",
      isTrending: state.isTrending,
      name: state.modelName,
      gallery: uploadedImage,
      isPrompt: state.isPrompt,
      isNegativePrompt: state.isNegativePrompt,
      isInitialImage: state.isInitialImage,
      isNumberOfGeneration: state.isNumberOfGeneration,
      isAspectRatio: state.isAspectRatio,
      tags: tags,
      modelIndex: state.modelIndex,
      liveHosting: true,
      isRemoveBg: state.isRemoveBg,
      isMusicIcon: state.isMusicIcon,
      apiPath: state.apiPath,
      method: state.method,
      // Added fields
      isStyle: state.isStyle,
      isAdvanceSetting: state.isAdvanceSetting,
      refimage2: state.refimage2,
      iscountry: state.iscountry,
      isbgcolor: state.isbgcolor,
      isrenderMode: state.isrenderMode,
      refimage3: state.refimage3,
      faceimage: state.faceimage,
      isQuality: state.isQuality,
      style2: state.style2,
      isoutfit: state.isoutfit,
      islanguage: state.islanguage,
      isgender: state.isgender,
      isvoice: state.isvoice,
      isformate: state.isformate,
      isduration: state.isduration,
      addByMusic: state.addByMusic,
      isDuration2: state.isDuration2,
      isplatform: state.isplatform,
      addAiMusic: state.addAiMusic,
      addAivoiceover: state.addAivoiceover,
      addAicaption: state.addAicaption,
      isAdvanceSetting2: state.isAdvanceSetting2,
    };

    if (state.isAspectRatio) input.aspectRatioPresets = aspectRatio;
    if (state.isNegativePrompt) input.negativePromptPresets = negativeSamplePrompt;
    if (state.isPrompt) {
      input.isSamplePrompt = true;
      input.samplePrompt = samplePrompts;
    }

    return input;
  };

  // Common function to check required fields
  const validateFields = () => {
    // if (
    //   !state.modelName ||
    //   !state.modelKey ||
    //   !state.categoryName ||
    //   tags.length < 1 ||
    //   uploadedImage.length < 1 ||
    //   (state.isPrompt && samplePrompts.length < 1) ||
    //   (state.isNegativePrompt && negativeSamplePrompt.length < 1) ||
    //   (state.isAspectRatio && aspectRatio.length < 1)
    // ) {
    //   alert("Please fill required fields");
    //   return false;
    // }
    return true;
  };

  const handleModelAdd = async () => {
    if (!validateFields()) return;
    setIsloading(true);
    const input = createInputData();

    try {
      const response = await axios.post(`${BASE_URL}/add-ai-model`, input, config);
      console.log("Model added successfully:", response.data);
      setUrlKey(`https://videotool.appypie.com/playground/${response.data.data.key}`);
      setIsloading(false);
      onOpen();
    } catch (error) {
      setIsloading(false);
      console.error("Error adding model:", error);

      // Show error toast if key already exists
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.error || "An error occurred while adding the model.");
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleLogout = () => {
    // Remove auth and loginTimestamp from localStorage
    localStorage.removeItem("auth");
    localStorage.removeItem("loginTimestamp");

    // Optionally, you can redirect to the login page after logout
    window.location.href = "/login"; // Or use `navigate("/login")` if using `useNavigate`
  };

  return (
    <div className="flex flex-col md:flex-row justify-between my-4">
      <ToastContainer />
      <div className="md:w-1/2 p-4 border border-gray-300 rounded-lg bg-gray-50">
        <h2 className="text-lg font-semibold mb-2">Live JSON Data</h2>
        <textarea
          className="w-full  h-[600px] overflow-scroll p-2 border rounded-lg bg-gray-100 focus:outline-none"
          readOnly
          value={JSON.stringify(createInputData(), null, 2)}
        />
      </div>

      <div className="md:w-1/2 p-4  overflow-scroll">
        {isloading ? (
          <>
            <div className="bg-gray-200 w-full h-full flex justify-center items-center">
              <div className="flex min-h-[100%] w-full items-center justify-center bg-gray-200">
                <div className="flex flex-col">
                  <h4 className="mb-8">Loading....</h4>

                  <div className="flex flex-row space-x-4">
                    <div
                      className="w-12 h-12 rounded-full animate-spin
                    border-2 border-dashed border-blue-500 border-t-transparent"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between">
              <h3 className="text-[20px] ">Current Interface: stable-diffusion</h3>
              <div onClick={handleLogout}>
                <svg
                  className="h-8 w-8 text-red-500"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                  <path d="M7 12h14l-3 -3m0 6l3 -3" />
                </svg>
              </div>
            </div>

            <div>
              {/* Model Details */}
              <div>
                {/* Model Name */}
                <div>
                  <p className="text-[20px] my-3">Model Index</p>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button variant="bordered" className="capitalize">
                        {state.modelIndex ? state.modelIndex : "Select Model Index"}
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                      aria-label="Single selection example"
                      variant="flat"
                      disallowEmptySelection
                      selectionMode="single"
                      selectedKeys={state.categoryName}
                      onSelectionChange={handleDropdownIndex}
                    >
                      {/* {Array.from({ length: AllModel?.length+1 }, (_, index) => (
                              <DropdownItem key={index + 1}>{index + 1}</DropdownItem>
                            ))} */}
                      {/* <DropdownItem>1</DropdownItem>
                            <DropdownItem>2</DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>
                  <Input
                    isRequired
                    type="text"
                    label="Model Name"
                    variant="underlined"
                    id="modelName"
                    name="modelName"
                    value={state.modelName}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    className="max-w-xs"
                  />
                </div>
                {/* Model Key */}
                <div>
                  <Input
                    isRequired
                    type="text"
                    label="Model Key"
                    variant="underlined"
                    id="modelKey"
                    name="modelKey"
                    value={state.modelKey}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    className="max-w-xs"
                  />
                </div>
                {/* Tags */}
                <div className="">
                  <div className="flex gap-2">
                    <Input
                      type="text"
                      label="Tags"
                      variant="underlined"
                      id="modelTag"
                      name="modelTag"
                      value={state.modelTag}
                      onChange={(e) => {
                        handleInput(e);
                      }}
                      className="max-w-xs"
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        handleAddTag("tags");
                      }}
                    >
                      Add
                    </Button>
                  </div>

                  <div className="mt-4 flex gap-2 mb-4 flex-wrap">
                    {tags.map((tag, index) => (
                      <Chip key={index} onClose={() => handleClose(tag, "tag")} variant="flat">
                        {tag} 
                      </Chip>
                    ))}
                  </div>
                </div>
                {/* Category Name Dropdown */}
                <div>
                  <h3 className="my-4">Category Name</h3>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button variant="bordered" className="capitalize">
                        {state.categoryName ? state.categoryName : "Select Category Name"}
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Single selection example"
                      variant="flat"
                      disallowEmptySelection
                      selectionMode="single"
                      selectedKeys={state.categoryName}
                      onSelectionChange={handleDropdownselect}
                    >
                      <DropdownItem key="Image Models">Image Models</DropdownItem>
                      <DropdownItem key="Video Models">Video Models</DropdownItem>
                      <DropdownItem key="Sticker Models">Sticker Models</DropdownItem>
                      <DropdownItem key="3D Models">3D Models</DropdownItem>
                      <DropdownItem key="Utility Model">Utility Models</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="my-2">
                  <h3 className="my-4">Category</h3>
                  <Input
                    type="text"
                    label="Category"
                    variant="underlined"
                    id="category"
                    name="category"
                    value={state.category}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    className="max-w-xs"
                  />
                </div>
                {/* Model Image */}
                <div className="flex gap-2 mt-4">
                  <Button endContent={"*"} onClick={() => document.getElementById("file-upload").click()}>
                    Upload Model Image
                  </Button>
                  <input
                    id="file-upload"
                    type="file"
                    multiple
                    accept="image/png, image/jpeg"
                    onChange={handleModelFile}
                    style={{ display: "none" }}
                  />
                  {uploadedImage.length > 0 &&
                    uploadedImage.map((img, index) => (
                      <div className="relative" key={index}>
                        <Image width={100} height={100} alt="Model Image" src={img} />
                        <span
                          className="crossbtn absolute top-0 right-0"
                          style={{ zIndex: 999 }}
                          onClick={() => {
                            handleClose(img, "uploadedImage");
                          }}
                        >
                          {"X"}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              {/* Model UI Details */}
              <div>
                <h3 className="my-4">Model UI Details</h3>
                {/* isPrompt */}
                <div>
                  <Checkbox id="isPrompt" name="isPrompt" type="checkbox" checked={state.isPrompt} onChange={(e) => handleInput(e)}>
                    Prompt
                  </Checkbox>
                  {state.isPrompt && (
                    <div className="">
                      <div className="flex gap-2">
                        <Input
                          type="text"
                          label="Sample Prompt"
                          variant="underlined"
                          id="samplePrompt"
                          name="samplePrompt"
                          value={state.samplePrompt}
                          onChange={(e) => {
                            handleInput(e);
                          }}
                          className="max-w-xs"
                        />
                        <Button
                          color="primary"
                          onClick={() => {
                            handleAddTag("samplePrompts");
                          }}
                        >
                          Add Prompt
                        </Button>
                      </div>
                      <div className="mt-4 flex gap-2 mb-4 flex-wrap">
                        {samplePrompts.map((samplePrompt, index) => (
                          <Chip key={index} onClose={() => handleClose(samplePrompt, "samplePrompt")} variant="flat">
                            {samplePrompt}
                          </Chip>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {/* isNegativePrompt */}
                <div>
                  <Checkbox
                    id="isNegativePrompt"
                    name="isNegativePrompt"
                    type="checkbox"
                    checked={state.isNegativePrompt}
                    onChange={(e) => handleInput(e)}
                    defaultSelected={state.isNegativePrompt}
                    // checked="checked"
                  >
                    Negative Prompt
                  </Checkbox>
                  {state.isNegativePrompt && (
                    <div className="flex gap-2">
                      <Input
                        type="text"
                        label="Negative Prompt Name"
                        variant="underlined"
                        id="negativePromptName"
                        name="negativePromptName"
                        value={state.negativePromptName}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className="max-w-xs"
                      />
                      <Input
                        type="text"
                        label="Negative Prompt Presets"
                        variant="underlined"
                        id="negativePromptPresets"
                        name="negativePromptPresets"
                        value={state.negativePromptPresets}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className="max-w-xs"
                      />
                      <Button
                        color="primary"
                        onClick={() => {
                          handleAddTag("negativeSamplePrompt");
                        }}
                      >
                        Add Preset
                      </Button>

                      {negativeSamplePrompt.map((nsamplePrompt, index) => (
                        <Chip key={index} onClose={() => handleClose(nsamplePrompt, "negativeSamplePrompt")} variant="flat">
                          {nsamplePrompt?.name}: {nsamplePrompt?.value}
                        </Chip>
                      ))}
                    </div>
                  )}
                </div>
                {/* isInitialImage */}
                <div>
                  <Checkbox id="isInitialImage" name="isInitialImage" type="checkbox" checked={state.isInitialImage} onChange={(e) => handleInput(e)}>
                    Reference Image
                  </Checkbox>
                </div>
                {/* isNumberOfGeneration */}
                <div>
                  <Checkbox
                    id="isNumberOfGeneration"
                    name="isNumberOfGeneration"
                    type="checkbox"
                    checked={state.isNumberOfGeneration}
                    onChange={(e) => handleInput(e)}
                  >
                    Number of Generations
                  </Checkbox>
                </div>
                {/* Bg Remover */}
                <div>
                  <Checkbox
                    id="isRemoveBg"
                    name="isRemoveBg"
                    type="checkbox"
                    checked={state.isRemoveBg}
                    onChange={(e) => handleInput(e)}
                    defaultSelected={state.isRemoveBg}
                    // checked="checked"
                  >
                    Bg Remover
                  </Checkbox>
                </div>
                {/* Music */}
                <div>
                  <Checkbox
                    id="isMusicIcon"
                    name="isMusicIcon"
                    type="checkbox"
                    checked={state.isMusicIcon}
                    onChange={(e) => handleInput(e)}
                    defaultSelected={state.isMusicIcon}
                    // checked="checked"
                  >
                    Background Music
                  </Checkbox>
                </div>
                {/* Trending */}
                <div>
                  <Checkbox
                    id="isTrending"
                    name="isTrending"
                    type="checkbox"
                    checked={state.isTrending}
                    onChange={(e) => handleInput(e)}
                    defaultSelected={state.isTrending}
                    // checked="checked"
                  >
                    Trending
                  </Checkbox>
                </div>
                {/* AspectRatio */}
                <div>
                  <Checkbox
                    id="isAspectRatio"
                    name="isAspectRatio"
                    type="checkbox"
                    checked={state.isAspectRatio}
                    defaultSelected={state.isAspectRatio}
                    onChange={(e) => handleInput(e)}
                  >
                    Aspect Ratio
                  </Checkbox>
                  {state.isAspectRatio && (
                    <div className="flex gap-2">
                      <Input
                        type="text"
                        label="Name"
                        variant="underlined"
                        id="aspectRatioName"
                        name="aspectRatioName"
                        value={state.aspectRatioName}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className="max-w-xs"
                      />
                      <Input
                        type="number"
                        label="Width"
                        variant="underlined"
                        id="aspectRatioWidth"
                        name="aspectRatioWidth"
                        value={state.aspectRatioWidth}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className="max-w-xs"
                      />
                      x
                      <Input
                        type="number"
                        label="Height"
                        variant="underlined"
                        id="aspectRatioHeight"
                        name="aspectRatioHeight"
                        value={state.aspectRatioHeight}
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        className="max-w-xs"
                      />
                      <Button
                        color="primary"
                        onClick={() => {
                          handleAddTag("aspectRatio");
                        }}
                      >
                        Add Ratio
                      </Button>
                      {aspectRatio.map((ratio, index) => (
                        <Chip key={index} onClose={() => handleClose(ratio, "aspectRatio")} variant="flat">
                          <span>{ratio.name}</span>
                          <span>
                            {ratio.width}x{ratio.height}
                          </span>
                        </Chip>
                      ))}
                    </div>
                  )}
                  <div className="flex flex-col">
                  <Checkbox id="isStyle" name="isStyle" type="checkbox" checked={state.isStyle} onChange={(e) => handleInput(e)}>
                    Style
                  </Checkbox>
                  <Checkbox
                    id="isAdvanceSetting"
                    name="isAdvanceSetting"
                    type="checkbox"
                    checked={state.isAdvanceSetting}
                    onChange={(e) => handleInput(e)}
                  >
                    Advance Setting
                  </Checkbox>
                  <Checkbox id="iscountry" name="iscountry" type="checkbox" checked={state.iscountry} onChange={(e) => handleInput(e)}>
                    Country
                  </Checkbox>
                  <Checkbox id="isbgcolor" name="isbgcolor" type="checkbox" checked={state.isbgcolor} onChange={(e) => handleInput(e)}>
                    Background Color
                  </Checkbox>
                  <Checkbox id="isrenderMode" name="isrenderMode" type="checkbox" checked={state.isrenderMode} onChange={(e) => handleInput(e)}>
                    Render Mode
                  </Checkbox>
                  <Checkbox id="isQuality" name="isQuality" type="checkbox" checked={state.isQuality} onChange={(e) => handleInput(e)}>
                    Quality
                  </Checkbox>
                  <Checkbox id="isoutfit" name="isoutfit" type="checkbox" checked={state.isoutfit} onChange={(e) => handleInput(e)}>
                    Outfit
                  </Checkbox>
                  <Checkbox id="isRemoveBg" name="isRemoveBg" type="checkbox" checked={state.isRemoveBg} onChange={(e) => handleInput(e)}>
                    Remove Background
                  </Checkbox>
                  <Checkbox id="islanguage" name="islanguage" type="checkbox" checked={state.islanguage} onChange={(e) => handleInput(e)}>
                    Language
                  </Checkbox>
                  <Checkbox id="isgender" name="isgender" type="checkbox" checked={state.isgender} onChange={(e) => handleInput(e)}>
                    Gender
                  </Checkbox>
                  <Checkbox id="isvoice" name="isvoice" type="checkbox" checked={state.isvoice} onChange={(e) => handleInput(e)}>
                    Voice
                  </Checkbox>
                  <Checkbox id="isformate" name="isformate" type="checkbox" checked={state.isformate} onChange={(e) => handleInput(e)}>
                    Format
                  </Checkbox>
                  <Checkbox id="isduration" name="isduration" type="checkbox" checked={state.isduration} onChange={(e) => handleInput(e)}>
                    Duration
                  </Checkbox>
                  <Checkbox id="addByMusic" name="addByMusic" type="checkbox" checked={state.addByMusic} onChange={(e) => handleInput(e)}>
                    Add by Music
                  </Checkbox>
                  <Checkbox id="isDuration2" name="isDuration2" type="checkbox" checked={state.isDuration2} onChange={(e) => handleInput(e)}>
                    Duration 2
                  </Checkbox>
                  <Checkbox id="isplatform" name="isplatform" type="checkbox" checked={state.isplatform} onChange={(e) => handleInput(e)}>
                    Platform
                  </Checkbox>
                  <Checkbox id="addAiMusic" name="addAiMusic" type="checkbox" checked={state.addAiMusic} onChange={(e) => handleInput(e)}>
                    Add AI Music
                  </Checkbox>
                  <Checkbox id="addAivoiceover" name="addAivoiceover" type="checkbox" checked={state.addAivoiceover} onChange={(e) => handleInput(e)}>
                    Add AI Voiceover
                  </Checkbox>
                  <Checkbox id="addAicaption" name="addAicaption" type="checkbox" checked={state.addAicaption} onChange={(e) => handleInput(e)}>
                    Add AI Caption
                  </Checkbox>
                  <Checkbox
                    id="isAdvanceSetting2"
                    name="isAdvanceSetting2"
                    type="checkbox"
                    checked={state.isAdvanceSetting2}
                    onChange={(e) => handleInput(e)}
                  >
                    Advance Setting 2
                  </Checkbox>
                  </div>
                </div>
              </div>
              {/* Generation Details*/}
              <div className="mt-5">
                <h4 className="text-[25px]">Generation Details</h4>
                <div>
                  {/* <Input
                    isRequired
                    type="text"
                    label="Add API Path"
                    variant="underlined"
                    name="apiPath"
                    value={state.apiPath}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    className="max-w-xs"
                  /> */}
                  <Input
                    isRequired
                    type="text"
                    label="method"
                    variant="underlined"
                    name="method"
                    value={state.method}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    className="max-w-xs"
                  />
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }} className="mb-4 mt-5">
              <Button
                color="warning"
                onClick={() => {
                  handleModelAdd();
                }}
              >
                Publish Model
              </Button>
            </div>
          </>
        )}
      </div>

      <Modal backdrop={"blur"} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Copy URL</ModalHeader>
              <ModalBody>
                <Input isReadOnly value={urlKey} label="Copy URL" variant="faded" className="mb-4" />
                <Button color="primary" variant="light" onPress={handleCopyUrl}>
                  Copy URL
                </Button>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Main;
