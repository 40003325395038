import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react"; // Assuming you're using NextUI
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Checkbox } from "@nextui-org/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BASE_URL = "https://us-central1-appydesigne-24e6c.cloudfunctions.net/dynamicModelApi";

const EditModelComponent = () => {
  const [models, setModels] = useState([]);
  const [selectedModelIndex, setSelectedModelIndex] = useState(null);
  const [isLiveHosting, setIsLiveHosting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading spinner state

  useEffect(() => {
    const fetchInitialModelData = async () => {
      const version = Math.random();
      const url = `https://imagesai.appypie.com/ModelJson/AiPlaygroundModel.json?v=${version}`;

      try {
        const response = await fetch(url);
        const jsonData = await response.json();
        setModels(jsonData.initialModelData);
      } catch (error) {
        console.error("Failed to fetch initial model data:", error);
      }
    };

    fetchInitialModelData();
  }, []);

  const handleSelectionChange = (key) => {
    setSelectedModelIndex(key);
    const selectedModel = models[key];
    setIsLiveHosting(selectedModel.data.liveHosting || false);
  };

  const handleCheckboxChange = () => {
    setIsLiveHosting(!isLiveHosting);
  };

  const handleEditModelClick = () => {
    setIsModalOpen(true); // Show modal on edit click
  };

  const handleEditModel = async () => {
    if (selectedModelIndex === null) return;

    const selectedModel = models[selectedModelIndex];
    const input = {
      key: selectedModel?.key,
      liveHosting: isLiveHosting,
    };

    setLoading(true); // Show spinner while pending
    setIsModalOpen(false);
    try {
      const response = await axios.put(`${BASE_URL}/edit-ai-model`, input);
      if (response.data.status) {
        // Show success toast
        toast.success("Model updated successfully!");
      } else {
        toast.error("Failed to update the model.");
        console.error("Error updating model:", response.data.error);
      }
    } catch (error) {
      toast.error("An error occurred while updating the model.");
      console.error("Error while calling edit API:", error);
    } finally {
      setLoading(false); // Hide spinner
      setIsModalOpen(false); // Close modal
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexWrap: "wrap",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <ToastContainer />
        <Dropdown>
          <DropdownTrigger>
            <Button variant="bordered" className="capitalize">
              {selectedModelIndex !== null ? `Model Index: ${models[selectedModelIndex]?.key}` : "Select Model"}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            style={{ maxHeight: "200px", overflowY: "auto" }}
            aria-label="Model selection dropdown"
            variant="flat"
            selectionMode="single"
            selectedKeys={selectedModelIndex !== null ? [selectedModelIndex] : []}
            onSelectionChange={(keys) => handleSelectionChange(keys.currentKey)}
          >
            {models.map((model, index) => (
              <DropdownItem key={index} value={index}>
                {model?.data?.name} (Index: {model?.key})
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>

        {selectedModelIndex !== null && (
          <Button onClick={handleEditModelClick} variant="solid" color="primary">
            Edit Model
          </Button>
        )}

        <Modal backdrop={"blur"} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Update Live Status</ModalHeader>
                <ModalBody>
                  <Checkbox isSelected={!isLiveHosting} onChange={handleCheckboxChange}>
                    Publish Model
                  </Checkbox>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onPress={handleEditModel}>
                    Update Model
                  </Button>
                  <Button color="danger" onPress={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
      {loading && (
        <>
          <div
            className="bg-gray-200 w-full min-h-screen flex justify-center items-center"
            style={{ position: "fixed", top: "0", bottom: "0px", left: "px", right: "0px", zIndex: "999" }}
          >
            <div className="flex min-h-screen w-full items-center justify-center bg-gray-200">
              <div className="flex flex-col">
                <h4 className="mb-8">Updating....</h4>

                <div className="flex flex-row space-x-4">
                  <div
                    className="w-12 h-12 rounded-full animate-spin
                    border-2 border-dashed border-blue-500 border-t-transparent"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditModelComponent;
