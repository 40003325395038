import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import Main from "./Main";
import EditModelComponent from "./EditModel";
import Login from "./Login";

const App = () => {
  // Initialize state based on localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("auth") === "true"
  );

  useEffect(() => {
    // Check if the auth cookie has expired
    const expiryCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("auth_expiry="));
    if (expiryCookie) {
      const expiryDate = new Date(expiryCookie.split("=")[1]);
      const currentDate = new Date();

      if (currentDate > expiryDate) {
        // Expiry date passed: clear localStorage and cookie
        localStorage.removeItem("auth");
        document.cookie = "auth_expiry=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC"; // Expire cookie
        setIsAuthenticated(false); // Update authentication state
      }
    }
  }, []);

  return (
    <NextUIProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Main />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/edit-model"
            element={
              isAuthenticated ? (
                <EditModelComponent />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/login"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
        </Routes>
      </Router>
    </NextUIProvider>
  );
};

export default App;
