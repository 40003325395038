import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Initialize the useNavigate hook



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show the loading spinner
    const headers = {
      "Content-Type": "application/json",
    };
    const url = "https://us-central1-appydesigne-24e6c.cloudfunctions.net/dynamicModelApi/login";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers,
        mode: "cors", // Ensure CORS is respected
        credentials: "include", // Include cookies or credentials
      });

      if (response.status === 200) {
        setIsAuthenticated(true);
        localStorage.setItem("auth", "true"); // Store auth status in localStorage
        // Set the expiry time to 3 days from now and store in cookies
        const expiryTime = new Date();
        expiryTime.setDate(expiryTime.getDate() + 3); // Add 3 days to current date
        document.cookie = `auth_expiry=${expiryTime.toUTCString()}; path=/`; // Store expiry time in cookie
        navigate("/"); // Redirect to the Main page after successful login
      } else {
        setIsAuthenticated(false);
        localStorage.setItem("auth", "false"); // Store auth status in localStorage
        setError("Invalid credentials. Please try again.");
      }
    } catch (err) {
      setIsAuthenticated(false);
      localStorage.setItem("auth", "false"); // Store auth status in localStorage
      setError("Authentication failed. Please check your inputs.");
    } finally {
      setLoading(false); // Hide the loading spinner after operation completes
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center text-blue-600">
          Login to Your Account
        </h2>
        {loading ? (
          <div className="flex justify-center mb-4">
            <div className="spinner-border animate-spin border-4 border-t-4 border-blue-600 rounded-full w-6 h-6"></div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 px-4 block w-full rounded-md h-[40px] border-gray-300 bg-[#eeeeee] shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 px-4 block w-full rounded-md h-[40px] border-gray-300 bg-[#eeeeee] shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <button
              type="submit"
              className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
            >
              Login
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
